<template>
<!-- 轮播图 -->
    <Banner :imgArr="state.arr" v-if="state.arr!=''" />
    <div class="section">
        <h2>{{state.name}}</h2>
        <div class="detailBox" v-html="state.caseDetail"></div>
    </div>
</template>
<style  lang="scss" scoped>
    .section{
        width: 9rem;
        margin: 0 auto;
        padding-bottom: .5rem;
        h2{
            font-size: .2rem;
            font-weight: normal;
            margin: .3rem 0 .2rem;
            text-align: center;
        }
        .detailBox{
            font-size: .14rem;
            line-height: .26rem;
            p{
                color: #666;
                text-align: left;
                text-indent: .2rem;
                
                margin: 0;
            }
            span{
                line-height: .26rem;
            }
        }
    }
</style>
<script>
import { useRoute } from 'vue-router'
import {onMounted,reactive} from "vue";
import {WOW} from 'wowjs' 
import 'animate.css'
import Banner from "@/components/banner1.vue"
import { getCaseDetail,getbannerData} from "../../api/request"
export default {
    components:{
        Banner
    },
    setup(){
        let state = reactive({
            arr:[],
            curId:0,
            name:"",
            caseDetail:""
        })
        const arr = [
            {img: require("@/assets/images/banner/case.jpg"),},
        ];
        onMounted(()=>{
            var wow = new WOW({
            boxClass: 'wow',    //需要执行动画元素的Class
            animateClass: 'animated',    //animation.css动画的Class
            offset: 100,    //距离可视区域多少开始执行动画
            mobile: true,    //是否在移动设备执行动画
            live: true    //异步加载的内容是否有效
            })
            wow.init()
            const route = useRoute()
            state.curId =  route.query.id
            console.log(state.curId)
            getData(state.curId)
        })
        // console.log(route.params)
        function getData(id){
            getbannerData({id:38}).then((res)=>{
                if(res.code == 200){
                state.arr = res.data
                }
            })
          getCaseDetail({id:id}).then((res) => {
              console.log(res)
              if(res.code == 200){
                state.name =  res.data.title
                state.caseDetail = res.data.content.replace(/<img/g,"<img style='max-width:100%;height:auto;margin:.1rem auto;display: block;'");
                
              }
          })
        }
        return{
            state,
            getData,
            arr
        }
    }
}
</script>